.st0{fill:#1A73E8;}
.st1{fill:#EA4335;}
.st2{fill:#4285F4;}
.st3{fill:#FBBC04;}
.st4{fill:#34A853;}
.st5{fill:#4CAF50;}
.st6{fill:#1E88E5;}
.st7{fill:#E53935;}
.st8{fill:#C62828;}
.st9{fill:#FBC02D;}
.st10{fill:#1565C0;}
.st11{fill:#2E7D32;}
.st12{fill:#F6B704;}
.st13{fill:#E54335;}
.st14{fill:#4280EF;}
.st15{fill:#34A353;}
.st16{clip-path:url(#SVGID_2_);}
.st17{fill:#188038;}
.st18{opacity:0.2;fill:#FFFFFF;enable-background:new    ;}
.st19{opacity:0.3;fill:#0D652D;enable-background:new    ;}
.st20{clip-path:url(#SVGID_4_);}
.st21{opacity:0.3;fill:url(#_45_shadow_1_);enable-background:new    ;}
.st22{clip-path:url(#SVGID_6_);}
.st23{fill:#FA7B17;}
.st24{opacity:0.3;fill:#174EA6;enable-background:new    ;}
.st25{opacity:0.3;fill:#A50E0E;enable-background:new    ;}
.st26{opacity:0.3;fill:#E37400;enable-background:new    ;}
.st27{fill:url(#Finish_mask_1_);}
.st28{fill:#FFFFFF;}
.st29{fill:#0C9D58;}
.st30{opacity:0.2;fill:#004D40;enable-background:new    ;}
.st31{opacity:0.2;fill:#3E2723;enable-background:new    ;}
.st32{fill:#FFC107;}
.st33{opacity:0.2;fill:#1A237E;enable-background:new    ;}
.st34{opacity:0.2;}
.st35{fill:#1A237E;}
.st36{fill:url(#SVGID_7_);}
.st37{fill:#FBBC05;}
.st38{clip-path:url(#SVGID_9_);fill:#E53935;}
.st39{clip-path:url(#SVGID_11_);fill:#FBC02D;}
.st40{clip-path:url(#SVGID_13_);fill:#E53935;}
.st41{clip-path:url(#SVGID_15_);fill:#FBC02D;}